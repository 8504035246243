import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`In general, I have great interest in software. Even though, I did not have taken the time to dive deep into all fields.`}</p>
    </blockquote>
    <p>{`Mostly I spend my time developing web application using Django (Python), Postgres, Celery, Redis, Nginx. For some side projects I have used Next.js with React and TailwindCSS. I am big fan of Docker and use Docker Compose for all my projects.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      